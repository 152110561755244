//サイトレイアウト構造を記述

#container {}
#main {}
#contents {
  padding: 0;
  @include mq-max(content) {
    width: $full-size;
    padding: 60px 0 0;
  }
}

//gridlex調整
[class*="grid"] {
 margin-bottom: -1 * $contentPadding; //gridlexを使うことでできてしまう下余白を相殺。
}
[class*="col-"] {
  img {
    width: 100%;
    display: block;
  }
}

///////////////////////////////////////////////////////////////////////////////////////////////////////

//  基本ブロックレイアウト

///////////////////////////////////////////////////////////////////////////////////////////////////////
.l-section {
  width: 100%;
  max-width: $contentWidth;
  margin: 0 auto;
  padding: 60px 0;
  @media screen and (max-width:$contentWidth + $contentPadding) {
    padding: 0 $contentPadding 60px;
  }
  @include mq-max(sm) {
    padding: 0 $contentPadding/2 60px;//お好みで変更
  }

  &--full {
    max-width: 100%;
  }

  &--wide {
    max-width: 1280px;
    .l-section__inner {
      width: 100%;
      max-width: $contentWidth;
      margin: 0 auto;
    }
  }

  &__inner {
    padding: 0 0 60px;//お好みで変更
    &:last-child {
      padding-bottom: 0;
    }
  }
  &__medium {
    max-width: 80%;
    margin: 0 auto;
    @include mq-max(xs) {
      max-width: 100%;
    }
  }
  &__small {
    max-width: 50%;
    margin: 0 auto;
    @include mq-max(xs) {
      max-width: 100%;
    }
  }

  //grid調整(デフォで付く下余白をマージンで相殺)
  [class*="grid"] {
    margin-bottom: -1 * $contentPadding;

    //2カラムレイアウト（共通）
    &.l-bgRect,
    &.l-bgRect--reverse {
      align-items: center;
      @include gridTune(60px);
      margin-left: -25px;
      margin-right: -25px;
      margin-bottom: 80px !important;
      @include mq-max(content) {
        margin-bottom: 40px !important;
      }
      &:last-child {
        margin-bottom: -1 * 60px !important;
        @include mq-max(content) {
          margin-bottom: -1 * $contentPadding;
        }
      }
      img {
        display: block;
        width: 100%;
      }
    }
  }
}

//2カラムレイアウト（背景に四角付き）
.l-bgRect {
  &__wrap {
    overflow: hidden;
  }
  &__item {
    position: relative;
    flex-basis: calc(50% + 15px) !important;
    max-width: calc(50% + 15px) !important;
    @include mq-max(content) {
      flex-basis: 50% !important;
      max-width: 50% !important;
    }
    @include mq-max(sm) {
      flex-basis: 100% !important;
      max-width: 100% !important;
    }
    &:before,
    &:after {
      content: '';
      background-color: $bg-default;
      height: 320px;
      position: absolute;
      top: 42px;
      @include mq-max(content) {
        height: 83%;
      }
      @include mq-max(xs) {
        height: 78%;
      }
    }
    &:before {
      width: 560px;
      display: block;
      left: 30px;
      z-index: -1;
      @include mq-max(content)  {
        width: calc(100% + #{$contentPadding});
        left: -30px;
      }
    }
    &:after {
      width: calc((100vw - #{$contentWidth}) / 2);
      left: calc((-1 * (100vw - #{$contentWidth}) / 2) + (60px / 2));
      @include mq-max(content) {
        display: none;
      }
    }
  }
  &__desc {
    flex-basis: calc(50% - 15px) !important;
    max-width: calc(50% - 15px) !important;
    @include mq-max(content) {
      flex-basis: 50% !important;
      max-width: 50% !important;
    }
    @include mq-max(sm) {
      flex-basis: 100% !important;
      max-width: 100% !important;
    }
  }

  &--reverse {
    .l-bgRect__item {
      &:before {
        left: auto;
        right: 30px;
        @include mq-max(content) {
          right: -30px;
        }
      }
      &:after {
        left: auto;
        right: calc((-1 * (100vw - #{$contentWidth}) / 2) + (60px / 2));;
      }
    }
  }
}

//background full width(間隔初期値60px)
@mixin bg_scss($space:60px) {
  padding: $space 0;//お好みで変更
  // margin-bottom: $space*2;
  &:last-child {
    margin-bottom: 0;
  }
  @include mq-max(sm) {
    margin: 0 0 $space;//お好みで変更
    &:last-child {
      margin-bottom: 0;
    }
  }
  @include mq-max(xs) {
    padding: ($space / 2) 0;//お好みで変更
  }
  &>.l-section {
    padding: 0 0 $space;
    @include mq-max(content) {
      padding: 0 $contentPadding $space;
    }
    @include mq-max(sm) {
      padding: 0 20px $space;
    }
    &:last-child {
      padding-bottom: 0;
    }
  }
}

.l-section--keyColor {
  background-color: $keyColor;
  @include bg_scss();
}
.l-section--accentColor {
  background-color: $accentColor;
  @include bg_scss();
}
.l-section--bgColor {
  background-color: $bg-default;
  @include bg_scss();
}



///////////////////////////////////////////////////////////////////////////////////////////////////////

//  text-align

///////////////////////////////////////////////////////////////////////////////////////////////////////
.u-taL {
  text-align: left !important;
}
.u-taC {
  text-align: center !important;
}
.u-taR {
  text-align: right !important;
}

///////////////////////////////////////////////////////////////////////////////////////////////////////

//  only系

///////////////////////////////////////////////////////////////////////////////////////////////////////
.u-only-xs {
  display: none !important;
  @include mq-max(xs) {
    display: block !important;
  }
}
.u-only-sm {
  display: none !important;
  @include mq-max(sm) {
    display: block !important;
  }
  @include mq-max(xs) {
    display: none !important;
  }
}
.u-only-md {
  display: none !important;
  @include mq-max(md) {
    display: block !important;
  }
  @include mq-max(sm) {
    display: none !important;
  }
}
.u-only-lg {
  @include mq-max(md) {
    display: none !important;
  }
}

.u-under-sm {
  display: none !important;
  @include mq-max(sm) {
    display: block !important;
  }
}

.u-not-under-sm {
  @include mq-max(sm) {
    display: none !important;
  }
}

.u-not-xs {
  @include mq-max(xs) {
    display: none !important;
  }
}

///////////////////////////////////////////////////////////////////////////////////////////////////////

//  ページ内アンカー位置調整

///////////////////////////////////////////////////////////////////////////////////////////////////////
// .anchorReposition {
//   padding-top: $headerHeight + 10px;
//   margin-top: -1 * $headerHeight - 10px;
//   @include mq-max(md) {
//     padding-top: $headerHeight-sp + 10px;
//     margin-top: -1 * $headerHeight-sp - 10px;
//   }
// }

///////////////////////////////////////////////////////////////////////////////////////////////////////

//  layout

///////////////////////////////////////////////////////////////////////////////////////////////////////

//互い違い
.l-alternate {
//  margin-bottom: 0 + (-1 * $contentPadding);
  &__wrap {
    margin-bottom: 60px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
