//コンバージョンエリア　インクルードパーツ
.c-cvArea {
  background-color: $accentColor;
  padding: 60px 0;
  overflow: hidden;
  &__inner {
    max-width: 800px;
    margin: 0 auto;
  }
  &__heading {
    text-align: center;
    font-size: 22px;
    color: $white;
    margin-bottom: 38px;
    &-sub {
      font-size: 18px;
      text-align: center;
      color: $white;
      margin-bottom: 5px;
    }
  }

  &__list {
    @include gridTune(50px);
    @include mq-max(bp820) {
      flex-direction: column;
      align-items: center;
    }
    @include mq-max(sm) {
      margin-left: 0;
      margin-right: 0;
    }
  }
  &__item {
    text-align: center;
    // &--tel {}
    &--mail {
      .c-btn {
        border: 1px solid $white;
        margin-bottom: 0;
        &>span {
          font-size: 22px;
          border: none;
          padding: 18.5px 40px;
          @include mq-max(xs) {
            font-size: 20px;
          }
          &:before,
          &:after {
            background-color: $keyColor;
          }
        }
      }
    }
  }

  //電話番号
  &__tel {
    display: inline-block;
    font-size: 33px;
    font-weight: 600;
    color: $white !important;
    border: 1px solid $white;
    padding: 7.5px 40px;
    margin-bottom: 5px;
    @include mq-max(xs) {
      font-size: 28px;
    }
    &>span {
      &:before {
        content: '';
        background: url('/inc/image/common/ico_tel-white.png')no-repeat center / contain;
        width: 42px;
        height: 42px;
        display: inline-block;
        vertical-align: sub;
        margin-right: 7px;
      }
    }
  }

  &__txt {
    font-size: 16px;
    color: $white;
    text-align: center;
    font-weight: 400;
  }
}