.l-navigation {
  @include mq-max(md) {
    display: none;
    html.open &.active > ul {
      overflow-y: scroll;
      height: calc(100vh - #{$headerHeight-sp});//画面高さ - fixedヘッダーの高さ
      padding-bottom: 120px;
    }
  }
  &.active {
    transform: translateY(0%);
  }
  &__inner {
    padding: 0;
    display: flex;
    @include mq-max(md) {
      display: block;
      height: calc(100vh - #{$headerHeight-sp});
      overflow-y: scroll;
      background-color: rgba($accentColor,.9);
      padding-top: 30px - 8px;
    }
  }

  //ホバー時アンダーラインを引く
  &__line {
    position: relative;
    display: inline-block;
    &:before {
      content: '';
      display: inline-block;
      width: 0%;
      height: 2px;
      background-color: $keyColor;
      position: absolute;
      bottom: -2px;
      left: 0;
      transition: width $transition;
      @include mq-max(md) {
        display: none;
      }
    }
  }

  &__item {
    &:last-child {
      margin-right: 0;
    }
    @include mq-max(md) {
      position: relative;
      margin-right: 0;
    }
    &.is-sp {
      display: none;
      @include mq-max(md) {
        display: block;
      }
    }
    a {
      color: $textColor;
      font-weight: 600;
      @include mq-max(md) {
        color: $white !important;
      }
    }
    &>a {
      text-decoration: none;
      color: $textColor;
      display: inline-block;
      padding: 28px 11px;
      position: relative;
      @include mq-max(lg) {
        font-size: 14px;
      }
      @include mq-max(md) {
        display: block;
        padding: 8px 10px 8px 36px;
        font-size: 18px;
        &:before {
          content: '';
          display: inline-block;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 5.4px 0 5.4px 10.2px;
          border-color: transparent transparent transparent $white;
          position: absolute;
          top: 50%;
          left: 20px;
          transform: translateY(-50%);
        }
      }
      &:hover {
        color: $keyColor;
        .l-navigation__line {
          &:before {
            width: 100%;
          }
        }
      }
      //現在地
      &.current {
        color: $keyColor;
        .l-navigation__line {
          &:before {
            width: 100%;
          }
        }
      }
    }

    //下層メニュー
    &.has-dropdown {
      &>a{
        position: relative;
        &:after {
          @include fa('f078');
          font-size: 12px;
          margin-left: 4px;
          @include mq-max(md) {
            @include fa('f067');
            position: absolute;
            top: 50%;
            right: 16px;
            transform: translateY(-50%);
            margin-left: 0;
            display: none;
          }
        }
        &.has-dropdown {
          &:after {
            @include mq-max(md) {
              content: '\f068';
            }
          }
        }
      }
      &:hover {
        .l-dropDown {
          top: $headerHeight; // headerの高さ
          opacity: 1;
          visibility: visible;
          @include mq-max(md) {
            top: auto;
          }
          a {
            transition: color $transition,
                        height $transition;
            color: $white;
          }
        }
      }
      .l-dropDown {
        position: absolute;
        // top: $headerHeight - 30px; // headerの高さ - 30px
        top: $headerHeight; // headerの高さ - 30px
        visibility: hidden;
        opacity: 0;
        transition: all .5s ease;
        z-index: -1;
        left: 0;
        display: flex;
        justify-content: center;
        width: 100%;
        background-color: $keyColor;
        @include mq-max(md) {
          background-color: transparent;
          position: relative;
          transition: none;
          top: auto;
          opacity: 1;
          visibility: visible;
          z-index: 1;
          display: block;
          margin-bottom: 10px;
        }
        li {
          @include mq-max(md) {
            border-bottom: none;
          }
          &:last-child {
            border: none;
          }
          a {
            padding: 23px 30px;
            white-space: nowrap;
            display: block;
            text-decoration: none;
            color: $white;
            width: 100%;
            @include mq-max(md) {
              padding: 5px 10px 5px 50px;
              // line-height: 0;
              // height: 0;
              // padding: 0;
              // opacity: 0;
              // transition: height $transition,
              //             opacity 0.4s,
              //             padding $transition,
              //             line-height $transition;
            }
            &:hover {
              .l-navigation__line {
                &:before {
                  width: 100%;
                  background-color: $white;
                }
              }
            }
          }
        }
      }
      // &.is-opened {
      //   > .l-dropDown {
      //     @include mq-max(md) {
      //       opacity: 1;
      //       display: block;
      //       > li {
      //         // border-bottom: 1px solid $gray;
      //         // &:first-child {
      //         //   border-top: 1px solid $gray;
      //         // }
      //         // &:last-child {
      //         //   border-bottom: none;
      //         // }
      //       }
      //       > li > a {
      //         // background-color: $keyColor;
      //         font-size: 15px;
      //         opacity: 1;
      //         line-height: 1.5;
      //         // padding: 5px 10px 5px 40px;
      //         // height: auto !important;
      //       }
      //     }
      //   }
      // }
    }
  }
}

//強調ナビ
.l-navs {
  @include mq-max(md) {
    margin-top: 30px;
  }
  &__list {
    display: flex;
    align-items: center;
    margin-left: 29px;
    @include mq-max(lg) {
      margin-left: 15px;
    }
    @include mq-max(md) {
      margin-left: 0;
    }
  }
  &__item {
    &>a {
      color: $white;
      display: block;
      font-size: 14px;
      position: relative;
      transition: all $transition;
      &:hover {
        color: $white;
      }
    }
    &--accident {
      @include mq-max(md) {
        display: none;
      }
      &>a {
        background-color: $accentColor;
        padding: 19px 37px 19px 62px;
        @include mq-max(lg) {
          padding-left: 40px;
          padding-right: 15px;
        }
        &:before {
          content: '';
          background: url('/inc/image/common/ico_wrench-gray.png')no-repeat center / contain;
          width: 28.5px;
          height: 28.5px;
          display: inline-block;
          position: absolute;
          top: 50%;
          left: 24px;
          transform: translateY(-50%);
          @include mq-max(lg) {
            left: 13px;
            width: 28.5px * 0.7;
            height: 28.5px * 0.7;
          }
        }
        &:after {
          content: none !important;
        }
        &:hover {
          background-color: $accentColor-hover;
        }
      }
    }
    &--contact {
      @include mq-max(md) {
        width: 100%;
        padding: 0 20px;
      }
      &>a {
        background-color: $keyColor;
        padding: 29.5px 27px 29.5px 59px;
        @include mq-max(lg) {
          padding-left: 40px;
          padding-right: 15px;
        }
        @include mq-max(md) {
          font-size: 18px;
          font-weight: 600;
          background-color: $white;
          color: $accentColor;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 26.5px 10px;
        }
        &:before {
          content: '';
          background: url('/inc/image/common/ico_mail-gray.png')no-repeat center / contain;
          width: 28px;
          height: 22px;
          display: inline-block;
          position: absolute;
          top: 50%;
          left: 26px;
          transform: translateY(-50%);
          @include mq-max(lg) {
            left: 16px;
            width: 28px * 0.7;
            height: 22px * 0.7;
          }
          @include mq-max(md) {
            background-image: url('/inc/image/common/ico_mail-aqua.png');
            width: 31.8px;
            height: 25px;
            position: static;
            transform: none;
            margin-right: 7px;
          }
        }
        &:hover {
          background-color: $keyColor-hover;
        }
      }
    }

    //通常のものとは別設定(故障かな？と思ったら)
    &.has-dropdown {
      &>a{
        position: relative;
        &:after {
          @include fa('f078');
          font-size: 12px;
          margin-left: 4px;
        }
      }
      &:hover {
        .l-dropDown {
          top: $headerHeight; // headerの高さ
          opacity: 1;
          visibility: visible;
          a {
            transition: color $transition,
                        height $transition;
            color: $white;
          }
        }
      }
      .l-dropDown {
        position: absolute;
        top: $headerHeight; // headerの高さ
        visibility: hidden;
        opacity: 0;
        transition: all .5s ease;
        z-index: -1;
        left: 0;
        display: flex;
        justify-content: center;
        width: 100%;
        background-color: $accentColor;
        padding: 41.5px 0;
        li {
          border-left: 1px solid $white;
          &:last-child {
            border-right: 1px solid $white;
          }
          &.l-navs--shindan {
            &>a {
              &:before {
                content: '';
                background: url('/inc/image/common/ico_loupe-white.png')no-repeat center / contain;
                width: 33px;
                height: 33px;
                display: inline-block;
              }
            }
          }
          &.l-navs--flow {
            &>a {
              &:before {
                content: '';
                background: url('/inc/image/common/ico_contract-white.png')no-repeat center / contain;
                width: 27.5px;
                height: 30px;
                display: inline-block;
              }
            }
          }
          &.l-navs--fixContact {
            &>a {
              &:before {
                content: '';
                background: url('/inc/image/common/ico_mail-white.png')no-repeat center / contain;
                width: 30px;
                height: 23px;
                display: inline-block;
              }
            }
          }
          a {
            position: relative;
            padding: 4px 20px 4px 60px;
            white-space: nowrap;
            display: block;
            text-decoration: none;
            color: $white;
            width: 100%;
            &:before {
              position: absolute;
              top: 50%;
              left: 20px;
              transform: translateY(-50%);
            }
            &:hover {
              .l-navigation__line {
                &:before {
                  width: 100%;
                  background-color: $white;
                }
              }
            }
          }
        }
      }
    }

  }
}