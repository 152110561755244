//ボックス
.c-box {
  background: $white;
  border: 1px solid $gray;
  border-radius: $border-radius;
  padding: 30px 20px;
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0
  }
}

//コンテンツボックス（横幅のみの調整で使いたい場合）
.c-contentBox {
  max-width: $contentWidth;
  margin: 0 auto 30px;
  &:last-child {
    margin-bottom: 0;
  }
  &--800 {
    max-width: 800px;
  }
}