.c-label {
  display: inline-block;
  border: 1px solid $accentColor;
  border-radius: 3px;
  font-size: 14px;
  padding: 4px 10px;
  width: 84px;
  text-align: center;

  &__inner {
    display: inline-block;
  }

  // $category_color:(
  //   aaa: $keyColor,
  //   bbb: #5B8E7D,
  //   ccc:  #B1CF5F,
  //   ddd:  #F4A259,
  //   eee: #BC4B51,
  // );

  // @each $name, $color in $category_color {
  //   &.c-label__#{$name} {
  //    background-color: $color;
  //    border: 1px solid $color;
  //   }
  // }
}
