//タブレット以下のfixedナビ
.l-spNavi {
  display: none;
  @include mq-max(md) {
    display: block;
    width: 100%;
    position: fixed;
    z-index: 95;
    bottom: -185px;
    left: 0;
    background-color: $accentColor;
    transition: bottom $transition;
    box-shadow: 0px -4px 10px rgba(#90D6FF, .6);
    &.is-open {
      bottom: 0;
      // .l-spNavi__list-wrap {
      //   display: block;
      // }
      // .l-spNavi__close {
      //   display: flex;
      // }
    }
  }

  &__heading {
    font-size: 15px;
    color: $white;
    margin: 0;
    padding: 15px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    &:before {
      content: '';
      display: inline-block;
      width: 28.5px;
      height: 28.5px;
      background: url('/inc/image/common/ico_wrench-gray.png')no-repeat center / contain;
      margin-right: 7.5px;
    }
  }

  &__list {
    padding: 10px 20px 45px;
    overflow: hidden;
    &-wrap {
      max-width: 375px;
      margin: 0 auto;
      // display: none;
    }
  }
  &__item {
    .l-spNavi__ico {
      &:after {
        content: '';
        display: inline-block;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
      }
    }
    &--shindan {
      .l-spNavi__ico {
        &:after {
          background: url('/inc/image/common/ico_loupe-blue.png')no-repeat center / contain;
          width: 47px;
          height: 47px;
          top: 18px;
        }
      }
    }
    &--flow {
      .l-spNavi__ico {
        &:after {
          background: url('/inc/image/common/ico_contract-blue.png')no-repeat center / contain;
          width: 43px;
          height: 47px;
          top: 18px;
          left: 53%;
        }
      }
    }
    &--contact {
      .l-spNavi__ico {
        &:after {
          background: url('/inc/image/common/ico_mail-blue.png')no-repeat center / contain;
          width: 47px;
          height: 37px;
          top: 23px;
        }
      }
    }
    &>a {
      display: block;
    }
  }
  &__ico {
    position: relative;
    &:before {
      content: '';
      width: 83px;
      height: 83px;
      display: block;
      background-color: $white;
      border-radius: 50%;
      margin: 0 auto 4px;
      box-shadow: $box-shadow;
    }
  }

  &__title {
    font-size: 13px;
    color: $white;
    text-align: center;
    margin: 0;
  }

  &__close {
    // display: none;
    color: $white !important;
    font-size: 12px;
    font-weight: 600;
    position: absolute;
    bottom: 17px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    &:before {
      @include fa('f00d');
      margin-right: 8px;
      line-height: 1;
      font-size: 15px;
    }
  }

}