///////////////////////////////////////////////////////////////////////////////////////////////////////

//  見出しクラス

///////////////////////////////////////////////////////////////////////////////////////////////////////

.c-ttl2 {
  font-size: 36px;
  color: $keyColor;
  margin-bottom: 30px;
  @include mq-max(sm) {
    font-size: 30px;
  }
  &--center {
    text-align: center;
  }
  &--black {
    color: $textColor;
  }
}

.c-ttl3 {
  font-size: 22px;
  color: $keyColor;
  margin-bottom: 15px;
  @include mq-max(sm) {
    font-size: 20px;
  }
  &--center {
    text-align: center;
  }
  &--black {
    color: $textColor;
  }
}


//小見出し付き
.c-heading {
  font-size: 36px;
  margin-bottom: 25px;
  @include mq-max(sm) {
    font-size: 30px;
  }
  &__sub {
    font-size: 21px;
    display: block;
    color: $keyColor;
    margin-bottom: 20px;
    @include mq-max(sm) {
      font-size: 18px;
      margin-bottom: 10px;
    }
    &>span {
      display: inline-block;
      position: relative;
      padding: 0 16px;
      &:before,
      &:after {
        content: '';
        display: block;
        width: 1px;
        height: 15px;
        background-color: $accentColor;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }
      &:before {
        left: 0;
      }
      &:after {
        right: 0;
      }
    }
  }
  &__main {
    font-size: inherit;
  }
}