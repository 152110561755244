//お問い合わせコンバージョン
.c-cvContact {
  background-color: $keyColor;
  padding: 60px 0;
  margin: 60px 0;
  @media screen and (max-width:$contentWidth + $contentPadding) {
    padding: 60px $contentPadding;
  }
  @include mq-max(sm) {
    padding: 60px $contentPadding/2;//お好みで変更
  }
  &__inner {
    max-width: $contentWidth;
    width: 100%;
    margin: 0 auto;
  }
  &__heading {
    text-align: center;
    color: $white;
    font-size: 22px;
    margin-bottom: 30px;
    @include mq-max(xs) {
      font-size: 20px;
    }
    &-sub {
      text-align: center;
      color: $white;
      font-size: 18px;
      margin-bottom: 10px;
    }
  }
  &__window {
    text-align: center;
  }
  &__tel {
    @extend .c-cvArea__tel;
  }
  &__txt {
    @extend .c-cvArea__txt;
  }
}