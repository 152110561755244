// リンク
.c-link {
  display: inline-block;
  position: relative;
  color: $accentColor !important;
  padding-left: 16px;
  font-size: 1em;
  font-weight: 600;
  transition: all $transition;
  &:hover {
    color: $accentColor-hover !important;
    &:before {
      left: 5px;
    }
  }
  &:before {
    @include fa('f105');
    position: absolute;
    top: 57%;
    left: 0;
    transform: translateY(-50%);
    line-height: 1;
    font-size: 1em;
    transition: all $transition;
    @include mq-max(sm) {
      font-size: 16px;
    }
  }
}