///////////////////////////////////////////////////////////////////////////////////////////////////////

//お知らせ一覧

///////////////////////////////////////////////////////////////////////////////////////////////////////

.c-newslist {
  dl {
    width: 100%;
    // dt,dd {}
    dt {
      width: 100%;
      display: flex;
      align-items: center;
      margin-bottom: 8px;
    }
    dd {
      font-size: 15px;
      line-height: 2em;
    }
  }

  a {
    &[target="_blank"]{
      dd:after{
        @include fa('f35d');
        color: $keyColor;
        margin-left: 10px;
        -webkit-font-smoothing:antialiased;
      }
    }
    &[href$=".pdf"] {
      dd:after {
        @include fa('f1c1');
        font-weight: 400;
        color: $red;
        margin-left: 10px;
        -webkit-font-smoothing:antialiased;
      }
    }
  }


  &__item {
    margin-bottom: 20px;
    @include mq-max(md) {
      border-bottom: 1px solid $accentColor;
      padding-bottom: 20px;
    }
    &:last-child {
      margin-bottom: 0;
    }
    &>a {
      display: flex;
      align-items: baseline;
      opacity: 1;
      transition: opacity $transition;
      &:after {
        content: none;
      }
      &:hover {
        opacity: .7;
      }
    }
  }
  &__date {
    display: inline-block;
    margin-right: 15px;
    font-size: 14px;
    font-weight: 600;
    color: $textColor;
  }
  .c-label {
    margin-right: 10px;
  }

}