.l-f {
  background-color: $white;
  @include mq-max(md) {
    padding-bottom: 238.5px - 185px;
  }
  &__inner {
    max-width: $contentWidth;
    width: 100%;
    margin: 0 auto;
    padding: 40px 20px 34.5px 90px;
    display: flex;
    @include mq-max(content) {
      flex-direction: column;
      padding: 50px 30px;
    }
    @include mq-max(xs) {
      padding: 30px 20px;
    }
  }
  &__left {
    padding-right: 60.5px;
    margin-right: 60.5px;
    border-right: 1px solid $accentColor;
    @include mq-max(content) {
      margin: 0;
      padding: 0;
      border-right: none;
    }
  }
  // &__right {}

  &__logo {
    width: 229px;
    display: block;
    margin-bottom: 15px;
    @include mq-max(content) {
      margin: 0 auto 15px;
    }
    > img {
      width: 100%;
    }
  }
  &__bnr {
    display: block;
    @include mq-max(content) {
      max-width: 335px;
      margin: 0 auto;
    }
    img {
      width: 100%;
    }
  }

  &__nav {
    display: flex;
    @include mq-max(content) {
      display: none;
    }
    &--sp {
      display: none;
      @include mq-max(content) {
        display: block;
        margin-top: 30px;
        .l-f__links {
          text-align: center;
          &>li {
            margin-bottom: 10px;
            &:last-child {
              margin-bottom: 0;
            }
            &>a {
              font-size: 14px;
              color: $accentColor;
            }
          }
        }
      }
    }
  }
  &__links {
    &--main {
      margin-right: 75px;
    }
    &--service {
      margin-right: 66px;
    }
    &--info {}
    &>li {
      font-size: 15px;
      color: $textColor;
      margin-bottom: 22px;
      &:last-child {
        margin-bottom: 0;
      }
      &>a {
        font-size: inherit;
        color: inherit;
        font-weight: 600;
      }
    }
    //サブリンク
    &-sub {
      &>li {
        margin-top: 22px;
        padding-left: 20px;
        &>a {
          font-size: inherit;
          color: inherit;
          font-weight: 400;
          display: block;
          padding-left: 10px;
          position: relative;
          &:before {
            content: '';
            display: block;
            width: 4px;
            height: 1px;
            background-color: $textColor;
            position: absolute;
            top: 12px;
            left: 0;
          }
        }
      }
    }
  }

  //Copyright
  &__copyright {
    font-size: 12px;
    text-align: center;
    display: block;
    color: $textColor;
    background-color: #ECF8FF;
    padding: 9px 0;
  }
}
