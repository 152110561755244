//他へリンク
.c-worksLink {
  margin-bottom: -50px;
  display: flex;
  flex-wrap: wrap;
  @include mq-max(bp700) {
    flex-direction: column;
  }
  &__item {
    margin-bottom: 50px;
    width: 50%;
    @include mq-max(bp700) {
      width: 100%;
    }
  }
  &__inner {
    position: relative;
    width: 100%;
    padding-top: (262 / 535 * 100%);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .c-btn {
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
    max-width: 300px;
    width: 100%;
    &>span {
      width: 100%;
      @include mq-max(sm) {
        padding: 15px 40px;
      }
    }
  }
}