.pageTop{
  position: fixed;
  display: block;
  bottom: 38px;
  right: 15px;
  z-index: 90;
  background-color: $accentColor;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  @include mq-max(md) {
    bottom: 90px;
    right: 10px;
  }
  @include mq-max(xs) {
    width: 50px;
    height: 50px;
  }
  &:before {
    content: '';
    background: url('/inc/image/common/ico_arrow-up.png')no-repeat center / contain;
    width: 25px;
    height: 25px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    @include mq-max(xs) {
      width: 19px;
      height: 19px;
    }
  }
  &:after {
    content: 'TOPへ戻る';
    display: inline-block;
    color: $accentColor;
    width: 100%;
    line-height: 1;
    font-size: 11px;
    font-weight: 600;
    text-align: center;
    position: absolute;
    bottom: -22px;
    @include mq-max(xs) {
      white-space: nowrap;
      bottom: -20px;
      left: -0.2em;
    }
  }
}
